
export const gaEvent = {
    bind(el, binding, vnodel) {
        let settings = binding.value;
        if(settings) {
            el.addEventListener(settings.listener, event => {
                gtag('event', settings.event, {
                    'event_category': settings.category
                  });
            });
        }
    }
}
