
export default {

  async getProduct(sku) 
  {
    return new Promise((resolve, reject) => {
      const headers = { Accept: 'application/json' };
      fetch('/shop/e-phonic/product.php?sku=' + sku, { headers }).then((data) => {
        data.json().then(data => {
          resolve(data);
        })
      })
    });
  },
  async getCart(sku, country, pwyw) 
  {
    return new Promise((resolve, reject) => {
      const headers = { Accept: 'application/json' };
      fetch('/shop/e-phonic/cart.php?sku=' + sku + '&country=' + country + '&pwyw=' + pwyw, { headers }).then((data) => {
        data.json().then(data => {
          resolve(data);
        })
      })
    });
  }

};
