
import Vue from 'vue'; /* important to make it work, this is not the default version when using import Vue from "vue"; */
import store from './store';
import router from './router'

/**
 * import base components
 */
import 'components/components.js';
import { scrollToDirective } from '../../directives/scroll-to.js'
import { gaEvent } from '../../directives/ga-event.js'

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
new Vue({ // eslint-disable-line no-new
  directives: {
    'scroll-to': scrollToDirective,
    'ga-event': gaEvent
  },
  router,
  store,
  el: '#app'
});

Vue.config.productionTip = false
