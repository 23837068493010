<template>
  <div>
    <slot :toggle="toggle" :show="show"></slot>
  </div>
</template>

<script>
export default {
  name: 'nav-top',
  props: {
    msg: String
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggle(menuId) {
      this.show = !this.show;
    }
  }
}
</script>
