<template>
  <div class="checkout">

    <div v-if="waitForTransaction" class="spinner-container">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="message">Completing transaction...</div>
    </div>

    <div v-if="loading || loadingPayPal" class="spinner-container">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="message">Loading...</div>
    </div>

    <ul class="progressbar">
      <li v-if="step == 2" class="completed" @click="onButtonBack">
        Your info<span>1</span>
      </li>

      <li v-else :class="{ active: step === 1, completed: step > 1 }">
        Your info<span>1</span>
      </li>
      <li :class="{ active: step === 2, completed: step > 2 }">
        Payment<span>2</span>
      </li>
      <li :class="{ completed: step === 3 }">Complete<span>3</span></li>
    </ul>

    <div v-if="!loading">
      <div v-if="!productIsLoaded">
        <p>Unknown product</p>
      </div>

      <div v-else>
        <div v-if="step === 1" :key="1">
          <h2><i aria-hidden="true" class="fas fa-shopping-cart"></i> &nbsp;{{ item.name }}</h2>

          <div class="attention-secondary mb-4 mt-4">
            <p class="small">{{item.info}}</p>
          </div>

          <h3>Your information</h3>

          <checkout-form
            :formData="form"
            :onSubmit="onButtonCheckout"
            :pwyw="item.pwyw"
          ></checkout-form>
        </div>
        <div v-else-if="step === 2" :key="2">
          <h2>Payment</h2>

          <div class="mb-5">
            <table class="table">
              <tbody>
                <tr>
                  <td>
                    {{ cart.name }}
                    <br><small>Registration key for {{form.email}}</small>
                  </td>
                  <td>
                    {{ formatPrice(cart.value, cart.currency) }}
                  </td>
                </tr>
                <tr>
                  <td>
                    TAX <small>({{ formatPrice(cart.taxPercentage) }}%)</small>
                  </td>
                  <td>
                    {{ formatPrice(cart.tax, cart.currency) }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>{{ formatPrice(cart.total, cart.currency) }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>Checkout using:</p>

          <div id="paypal-container"></div>
        </div>
        <div v-else-if="step === 3" :key="3" class="text-center">
          <h2>Payment complete!</h2>

          <svg
            class="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              class="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              class="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>

          <p>
            Thank you! Your product registration key will be send to
            <strong>{{ form.email }}</strong>
            after the payment has been verified.
          </p>
          <p><a href="/" class="btn btn-secondary">To homepage</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckoutService from "components/checkout/CheckoutService.js";
import CheckoutForm from "components/checkout/CheckoutForm.vue";

export default {
  components: {
    CheckoutForm,
  },
  name: "checkout",
  computed: {
    productIsLoaded() {
      return this.item.status === 200;
    },
  },
  data() {
    return {
      loading: false,
      loadingPayPal: false,
      waitForTransaction: false,
      item: {},
      cart: {},
      itemID: "",
      form: {
        email: "",
        country: "",
        pwywValue: '25'
      },
      step: 1,
      payalSrc: "https://www.paypal.com/sdk/js?currency=EUR&client-id=",
      env: {
        sandbox: {
          clientId:
            "AdsoetBEjxfMTQXalIyatAfl2JM9X82v4B0ZDwBI_Px8TCiFTYEiLdueYlQWhN6Hi5Tk1U1nF_Z5ffGi",
        },
        production: {
          clientId:
            "AeUI5aoit5KrLSKHZwb-Oqg98hqDyh7avMow1jDVw89jw0Bx2ID0_2pUt8Wm-cVGVjHN1DZ4NFyRvDHB",
        },
      },
    };
  },
  methods: {
    sendGAEvent(event, category) {
      if(gtag != 'undefined') {
        gtag('event', event, {
          'event_category': category
        });
      }
    },
    formatPrice(value, currency = "") {
      let currencySign = "";
      switch (currency) {
        default:
          break;
        case "EUR":
          currencySign = "€ ";
          break;
        case "USD":
          currencySign = "$ ";
          break;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return (
        currencySign + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      );
    },
    resetWaitForTransaction() {
      let _this = this;
      let selector = '[id^="paypal-overlay-"]';

      _this.onElementExist(
        selector,
        function () {
          _this.waitForTransaction = false;
          _this.onElementExist(
            '[id^="paypal-overlay-"]',
            function () {
              _this.waitForTransaction = true;
            },
            500
          );
        },
        500,
        false
      );
    },
    onButtonBack() {
      this.step = 1;
    },
    onButtonCheckout() {
      this.step = 2;
      this.loading = true;
      CheckoutService.getCart(this.item.sku, this.form.country, this.form.pwywValue).then(
        (response) => {
          this.loading = false;
          this.cart = response;
          this.createOrder();
        }
      );

      this.sendGAEvent('Checkout: Payment', 'nav');
    },
    onElementExist(selector, callback, checkFrequencyInMs, exist = true) {
      var startTimeInMs = Date.now();
      (function loopSearch() {
        if ((exist && document.querySelector(selector) !== null) || (!exist && document.querySelector(selector) === null)) {
          callback();
          return;
        } else {
          setTimeout(function () {
            loopSearch();
          }, checkFrequencyInMs);
        }
      })();
    },
    loadPayPalApi() {
      return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.onload = () => {
          resolve();
        };
        script.async = true;
        if(this.cart.sandbox) {
          script.src = this.payalSrc + this.env.sandbox.clientId;
        } else {
          script.src = this.payalSrc + this.env.production.clientId;
        }
        document.head.appendChild(script);
      });
    },
    createOrder() {
      let _this = this;

      this.loadingPayPal = true;
      this.loadPayPalApi().then(() => {
        _this.loadingPayPal = false;
        const order = {
          purchase_units: [
            {
              description: "Payment to E-Phonic",
              amount: {
                currency_code: this.cart.currency,
                value: this.cart.total.toString(),
                breakdown: {
                  item_total: {
                    currency_code: this.cart.currency,
                    value: this.cart.value.toString(),
                  },
                  tax_total: {
                    currency_code: this.cart.currency,
                    value: this.cart.tax.toString(),
                  },
                },
              },
              items: [
                {
                  name: this.cart.name,
                  unit_amount: {
                    value: this.cart.value.toString(),
                    currency_code: this.cart.currency,
                  },
                  tax: {
                    currency_code: this.cart.currency,
                    value: this.cart.tax.toString(),
                  },
                  quantity: "1",
                  sku: this.cart.sku,
                },
              ],
              custom_id: this.form.email + "|" + this.form.country,
            },
          ],
        };

        paypal
          .Buttons({
            createOrder: function (data, actions) {
              // This function sets up the details of the transaction, including the amount and line item details.
              return actions.order.create(order);
            },
            onApprove: function (data, actions) {
              return actions.order
                .capture()
                .then(function (details) {
       
                  _this.resetWaitForTransaction();
                  _this.step = 3;
                  _this.sendGAEvent('Checkout: Complete', 'nav');
                })
                .catch(function (e) {
                  // error
                    _this.resetWaitForTransaction();
                    _this.sendGAEvent('Checkout: Error', 'nav');
                });
            },
            onCancel: function (data, actions) {
   
                _this.resetWaitForTransaction();

              // Show a cancel page or return to cart
            },
          })
          .render("#paypal-container");
      });

      //
      // .paypal-checkout-sandbox-iframe
    },
  },
  mounted() {
    this.itemID = this.$route.query.item;
    this.loading = true;
    CheckoutService.getProduct(this.itemID).then((response) => {
      this.loading = false;
      this.item = response;
    });

    this.resetWaitForTransaction();
    this.sendGAEvent('Checkout: Your info', 'nav');
  },
};
</script>
